<template>
  <vs-row justify="center" align="center" :style="{ backgroundColor: colors.PRIMARY }">
    <div>
      <form>
        <v-container>
          <vs-row :style="{ padding: '20px' }" justify="center">
            <h4>Welcome to <b>{{ BRAND_NAME }}</b></h4>
          </vs-row>
          <vs-row justify="center" :style="{ paddingLeft: '10px' }">
            <vs-input :style="{ padding: '5px' }" v-model="email" placeholder="Email">
              <template #icon>
                <span class="material-symbols-outlined"> badge </span>
              </template>
            </vs-input>
            <vs-input :style="{ padding: '5px' }" type="password" v-model="password" placeholder="Password">
              <template #icon>
                <span class="material-symbols-outlined"> vpn_key </span>
              </template>
            </vs-input>
          </vs-row>
          <vs-row justify="center" :style="{ padding: '5px' }">
            <router-link :to="{ name: 'ForgotPassword' }">
              Forgot Password?
            </router-link>
          </vs-row>
          <vs-row justify="center" :style="{ padding: '5px' }">
            <router-link :to="{ name: 'Register' }">Create New Account
            </router-link>
          </vs-row>
          <vs-row justify="center" :style="{ padding: '10px' }">
            <v-btn @click.prevent="signIn()" dark rounded :color="colors.BLACK" type="submit">
              Sign In
            </v-btn>
          </vs-row>
          <vs-row justify="center" :style="{ padding: '2%' }">
            <v-btn rounded @click.prevent="googleSignIn()" type="submit">
              Sign In using
              <i :style="{ fontSize: '30px', padding: '5px', color: colors.GOOGLE }" class="fa-brands fa-google"></i>
            </v-btn>
          </vs-row>
          <vs-row justify="center" :style="{ padding: '2%' }">
            <v-btn rounded @click.prevent="facebookSignIn()" type="submit">
              Sign In using
              <i :style="{ fontSize: '30px', padding: '5px', color: colors.FACEBOOK }"
                class="fa-brands fa-facebook"></i>
            </v-btn>
          </vs-row>
        </v-container>
      </form>
    </div>
    <Alert :snackbar="alert" :text="alertMessage" :color="alertColor" @updateSnackbar="closeSnackbar" />
  </vs-row>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import db from '../firebase/firebaseInit';
import { COLORS } from '@/plugins/branding.js';
import { BRAND_NAME } from '@/plugins/utils.js';

export default {
  name: 'Login',
  data: () => ({
    user: '',
    login: true,
    email: '',
    password: '',
    alert: false,
    alertColor: null,
    alertMessage: null,
    colors: COLORS,
    BRAND_NAME
  }),
  components: {
    Alert: () => import('@/components/Alert.vue'),
  },
  methods: {
    closeSnackbar(bool) {
      this.alert = bool;
    },
    signIn() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {
          this.$router.push({ name: 'Home' });
        })
        .catch((err) => {
          this.alertColor = COLORS.RED;
          this.alertMessage = err.message.replace('Firebase:', '');
          this.alert = true;
        });
    },
    async googleSignIn() {
      const provider = new firebase.auth.GoogleAuthProvider();
      try {
        const result = await firebase.auth().signInWithPopup(provider);
        const user = result.user;
        await this.createOrUpdateUser(user);
        this.$router.push({ name: 'Home' });
      } catch (err) {
        this.alertColor = COLORS.RED;
        this.alertMessage = err.message.replace('Firebase:', '');
        this.alert = true;
      }
    },
    async facebookSignIn() {
      const provider = new firebase.auth.FacebookAuthProvider();
      try {
        const result = await firebase.auth().signInWithPopup(provider);
        const user = result.user;
        await this.createOrUpdateUser(user);
        this.$router.push({ name: 'Home' });
      } catch (err) {
        this.alertColor = COLORS.RED;
        this.alertMessage = err.message.replace('Firebase:', '');
        this.alert = true;
      }
    },
    async createOrUpdateUser(user) {
      const dataBase = db.collection('users').doc(user.uid);
      const userDoc = await dataBase.get();
      if (!userDoc.exists) {
        await dataBase.set({
          firstName: user.displayName.split(' ')[0],
          lastName: user.displayName.split(' ').slice(1).join(' '),
          email: user.email,
          address: null,
          phone: null,
        });
      }
    },
  },
};
</script>